@tailwind base;
@tailwind components;
@tailwind utilities;

/* 머테리얼 디자인 css 강제 조정 */
html {
  -webkit-font-smoothing: auto !important;
}

/* scss 써서 @extend 처리? ... */
#root {
  display: inherit;
  flex-direction: inherit;
  height: inherit;
  min-width: inherit;
}

/* scss 써서 @extend 처리? ... */
.App {
  display: inherit;
  flex-direction: inherit;
  height: inherit;
  min-width: inherit;
}

.activemodal {
  opacity: 1;
  z-index: 1000;
}

.activemodal2 {
  opacity: 1;
  z-index: 999;
}

.activemodalcommon {
  opacity: 1;
  z-index: 10000;
}

.swiper-button-prev {
  left: 0px !important;
  background: linear-gradient(to right, black, transparent);
}

.swiper-button-next {
  right: 0px !important;
  background: linear-gradient(to left, black, transparent);
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  padding-left: 13px !important;
  padding-right: 13px !important;
  height: 100% !important;
  top: 0% !important;
  margin-top: 0px !important;
}

.deco-page .swiper-button-prev {
  background: linear-gradient(to right, #252737 55%, transparent 100%);
}
.deco-page .swiper-button-next {
  background: linear-gradient(to left, #252737 55%, #25273700 100%);
}

:root {
  --swiper-navigation-size: 12px !important;
  --swiper-theme-color: #ffffff !important;
}

@keyframes blink-effect {
  50% {
    opacity: 0.5;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;
}

.chakra-modal__content-container{
  box-shadow: none !important;
  outline: none !important;
}

.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}
.profile-pic input {
  display: none;
}
.profile-pic img {
  position: absolute;
  object-fit: cover;
  width: 128px;
  height: 128px;
  box-shadow: 0 20px 30px 0 rgb(70 49 150 / 65%);
  border-radius: 100px;
  z-index: 0;
}
.profile-pic .-label {
  cursor: pointer;
  height: 128px;
  width: 128px;
}
.profile-pic:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10000;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}
.profile-pic span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}
.ReactTags__tag {
  border-radius: 50px;
  border-width: medium;
  cursor: text;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.ReactTags__tagInput {
  border-width: medium;
}
button:disabled {
  opacity: 0.5;
}

/* my-collection-list infiniteScroll 예외처리 */
.my-collection-list .infinite-scroll-component__outerdiv {
  width: 100%;
}

.example-swiper .swiper-button-next {
  background: none;
}
.example-swiper .swiper-button-prev {
  background: none;
}